import _ from "underscore";
import axios from "axios";
import Config from "../../config";

const app = {
    namespaced: true,
    state: {
        projectTypeOptions: [],
        fundingOptions: [],
        instOptions: [],
        empTypeOptions: [],
        countries: [],
        states: [],
        sexes: [],
        races: [],
        ethnicities: [],
        disabilities: [],
        workingGroups: [
            { text: "Aerosol Processes", value: 148, description: "this working group is focused on the understanding of processes that control spatial and time-related distribution of aerosols and their chemical, microphysical (occurring on a microscopic scale), and optical properties." },
            { text: 'Warm Boundary Layer Processes', value: 151, description: "this working group is focused on the understanding and model representation of processes controlling the structural and radiative properties of clouds, aerosols, and their interactions with the underlying surface in the lowest few kilometers of the atmosphere." },
            { text: 'Convective Processes', value: 149, description: "this working group is focused on the understanding and model representation of convective (heat-transferring) cloud processes and properties, including cloud cover, precipitation, life cycle, dynamics, and microphysics, over a range of spatial scales." },
            { text: 'High-Latitude Processes', value: 150, description: "this working group is focused on the understanding and model representation of physical processes controlling the surface energy budgets in northern and southern high-latitude regions." }
        ],
        isEnvDev: location.host === "armweb-dev.ornl.gov" ? true : false,
    },
    mutations: {
        updateProjectOptions (state, response) {
            state.projectTypeOptions = []
            if (response) {
                state.projectTypeOptions.push({
                    text: "Select one ...",
                    value: null,
                })

                response
                    .filter(projectType => projectType.projectTypeCode !== "U")
                    .forEach(projectType => {
                        state.projectTypeOptions.push({
                            text: projectType.projectTypeDesc,
                            value: projectType.projectTypeCode,
                        })
                    })
            }
        },
        updateInstOptions (state, response) {
            state.instOptions = []
            if (response) {
                _.each(response, (data) => {
                    state.instOptions.push(data.instName)
                })
            }
        },
        updateEmpTypeOptions (state, response) {
            state.empTypeOptions = []
            if (response) {
                state.empTypeOptions.push({
                    text: "Select one ...",
                    value: null,
                })
                _.each(response, (data) => {
                    state.empTypeOptions.push({
                        text: data.employmentTypeDesc,
                        value: data.employmentTypeCode
                    })
                })
            }
        },
        updateCountries (state, response) {
            state.countries = []
            if (response) {
                state.countries.push({
                    text: "Select one ...",
                    value: null,
                })
                _.each(response, (data) => {
                    state.countries.push({
                        text: data.countryName,
                        value: data.countryCode
                    })
                })
            }
        },
        updateStates (state, response) {
            state.states = []
            if (response) {
                state.states.push({
                    text: "Select one ...",
                    value: "0"
                })
                state.states.push({
                    text: "None",
                    value: null
                })
                _.each(response, (data) => {
                    state.states.push({
                        text: data.stateName,
                        value: data.stateCode
                    })
                })
            }
        },
        updateSexes (state, response) {
            state.sexes = []
            if (response) {
                state.sexes.push({
                    text: "Select one ...",
                    value: null,
                    order: 0,
                })
                _.each(response, (data) => {
                    state.sexes.push({
                        text: data.sexName,
                        value: data.sexCode,
                        order: data.sexOrder,
                    })
                })
                state.sexes.sort((a, b) => a?.order - b?.order)
            }
        },
        updateRaces(state, response) {
            state.races = []
            if (response) {
                _.each(response, (data) => {
                    state.races.push({
                        text: data.raceDesc,
                        value: data.raceCode,
                        order: data.displayOrder,
                    })
                })
                state.races.sort((a, b) => a?.order - b?.order)
            }
        },
        updateEthnicities(state, response) {
            state.ethnicities = []
            if (response) {
                _.each(response, (data) => {
                    state.ethnicities.push({
                        text: data.ethnicityDesc,
                        value: data.ethnicityCode,
                        order: data.ethnicityOrder,
                    })
                })
                state.ethnicities.sort((a, b) => a?.order - b?.order)
            }
        },
        updateDisabilities(state, response) {
            state.disabilities = []
            if (response) {
                _.each(response, (data) => {
                    state.disabilities.push({
                        text: data.disabilityDesc,
                        value: data.disabilityCode,
                        order: data.disabilityOrder,
                    })
                })
                state.disabilities.sort((a, b) => a?.order - b?.order)
            }
        },
    },
    actions: {
        getProjectTypeOptions({commit}) {
            axios.get(`${Config.api.url}/users/projects`).then((response) => {
                commit("updateProjectOptions", response.data)
            }).catch(error => {
                console.log("Error", error)
            })
        },
        async searchFundingOptions({}, filter) {
            return axios({
                method: "post",
                url: `${Config.elastic.url}/funders/_search?filter_path=hits.hits._source`,
                data: {
                    "size": 10,
                    "query": {
                        "query_string": {
                            "query": `*${filter}*`,
                            "fields": [
                                "crossref_entry.alt_names",
                                "crossref_entry.name",
                            ],
                        },
                    },
                    "_source": [
                        "crossref_entry.uri",
                        "crossref_entry.name",
                    ],
                },
            }).then(response => response?.data?.hits?.hits?.map(hit => hit?._source?.crossref_entry))
            .catch(error => console.error("Error", error))
        },
        async fetchFundingInfo({}, uri) {
            return axios({
                method: "post",
                url: `${Config.elastic.url}/funders/_search?filter_path=hits.hits._source`,
                data: {
                    "size": 1,
                    "query": {
                        "match": {
                            "crossref_entry.uri": `${uri}`,
                        },
                    },
                    "_source": [
                        "crossref_entry.uri",
                        "crossref_entry.name",
                    ],
                },
            }).then(response => response?.data?.hits?.hits?.map(hit => hit?._source?.crossref_entry))
            .catch(error => console.error("Error", error))
        },
        async searchAffiliationOptions({}, filter) {
            return axios({
                method: "post",
                url: `${Config.elastic.url}/institutions/_search?filter_path=hits.hits._source`,
                data: {
                    "size": 10,
                    "query": {
                        "query_string": {
                            "query": `*${filter}*`,
                            "fields": [
                                "ror_entry.acronyms",
                                "ror_entry.name",
                                "ror_entry.aliases",
                            ],
                        },
                    },
                    "_source": [
                        "ror_entry.name",
                        "ror_entry.id",
                        "ror_entry.country.country_name",
                    ],
                },
            }).then(response => response?.data?.hits?.hits?.map(hit => hit?._source?.ror_entry))
            .catch(error => console.error(error))
        },
        async fetchAffiliationInfo({}, id) {
            return axios({
                method: "post",
                url: `${Config.elastic.url}/institutions/_search?filter_path=hits.hits._source`,
                data: {
                    "size": 1,
                    "query": {
                        "match": {
                            "ror_entry.id": `${id}`,
                        },
                    },
                    "_source": [
                        "ror_entry.id",
                        "ror_entry.name",
                        "ror_entry.country.country_name",
                    ],
                },
            }).then(response => response?.data?.hits?.hits?.map(hit => hit?._source?.ror_entry))
            .catch(error => console.error("Error", error))
        },
        getEmpTypeOptions({commit}) {
            axios.get(`${Config.api.url}/users/empTypes`).then((response) => {
                commit("updateEmpTypeOptions", response.data)
            }).catch(error => {
                console.log("Error", error)
            })
        },
        getCountries({commit}) {
            axios.get(`${Config.api.url}/users/countries`).then((response) => {
                commit("updateCountries", response.data)
            }).catch(error => {
                console.log("Error", error)
            })
        },
        getStates({commit}) {
            axios.get(`${Config.api.url}/users/states`).then((response) => {
                commit("updateStates", response.data)
            }).catch(error => {
                console.log("Error", error)
            })
        },
        getSexes({commit}) {
            axios.get(`${Config.api.url}/sexes`).then((response) => {
                commit("updateSexes", response.data)
            }).catch((error => {
                console.log("Error", error)
            }))
        },
        getRaces({commit}) {
            axios.get(`${Config.api.url}/races`).then((response) => {
                commit("updateRaces", response.data)
            }).catch((error => {
                console.log("Error", error)
            }))
        },
        getEthnicities({commit}) {
            axios.get(`${Config.api.url}/ethnicities`).then((response) => {
                commit("updateEthnicities", response.data)
            }).catch((error => {
                console.log("Error", error)
            }))
        },
        getDisabilities({commit}) {
            axios.get(`${Config.api.url}/disabilities`).then((response) => {
                commit("updateDisabilities", response.data)
            }).catch((error => {
                console.log("Error", error)
            }))
        },
    }
}

export default app
